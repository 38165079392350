// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


.btn-light-cs{
    color: black;
    background-color: #E1E2E4;
    
}

@media only screen and (max-width: 574px) {
    .w-sm-75{
        width: 75vw;
    }

}
@media only screen and (min-width: 575px) {
    .w-sm-75{
        width: 75%;

    }

}

#alert{
    display: none;
}

#spinner{
    display: none;
}